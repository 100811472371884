import React, {useState,useEffect} from 'react'
import {useTheme} from '../components/Theming'
//import Container from '../components/Container'
import {graphql} from 'gatsby'
import Layout from '../components/Layout'
//import Hero from '../components/Hero'
//import Img from 'gatsby-image'
import {css} from '@emotion/core'
//import {Dialog} from '@reach/dialog'
import Lightbox from 'react-spring-lightbox'
import Img from 'gatsby-image/withIEPolyfill'
import {FaArrowRight,FaArrowLeft, FaWindowClose} from 'react-icons/fa'
import titel from './titel'
import './styles.css'
import SEO from '../components/SEO'

const Bacchanale = ({data}) => {
  const theme = useTheme()

  const [currentImageIndex, setCurrentIndex] = useState(0);
  const [images,setImages]=useState(null)
  const [light,setLight]=useState(false)
  
    function los(daten){
      let mdat=[]
       daten.map((item,index) => {
        
        let a=item.src
        let b=a.lastIndexOf('/')
        let c = a.slice(b+1,a.length)
        let d=c.split('.')
        let arr= parseInt(d[0])
        
        return mdat.push({src:item.src,id:arr,titel:titel.theaterdaten[arr-1].titel}) 
       })
      mdat.sort((a,b) => (parseInt(a.id) > parseInt(b.id)) ? 1: -1)
      
      return mdat
      
    }

  const gotoPrevious = () =>
      currentImageIndex > 0 && setCurrentIndex(currentImageIndex - 1);

  const gotoNext = () =>
      currentImageIndex + 1 < images.length &&
      setCurrentIndex(currentImageIndex + 1);

   useEffect(()=>{
       function makeImg(){
        const myImages= data.theater.nodes.map((item,index)=> {
     
          return item.childImageSharp.fluid
         })
        
         let daten = los(myImages)
       
         return setImages(daten)
        
      }
      makeImg()
      
   },[data.theater.nodes])   

   function handleClose(){
       setLight(!light)
   }
   function handleClose2(index,item){
   
    setCurrentIndex(index)
    setLight(!light)
  
    
}
   function mach(){
      
       currentImageIndex<images.length-1 &&
       setCurrentIndex(currentImageIndex+1)
   }
   function lmach(){
     
      currentImageIndex>=1 &&
      setCurrentIndex(currentImageIndex-1)
  }
 
  const CustomFooter =()=> {
    
      return <span style={{padding:4,left:'2%',zIndex:55000,color:'white',textAlign:'center',}}>
          Foto von {images[currentImageIndex].titel}  <br/>{images[currentImageIndex].id} / {images.length} </span>
  }

   const CustomLeftArrowButton =()=> {
     
   return <button style={{border:"blue",background:'none',position:'fixed',top:'50%',right:'1%',zIndex:2000}} onClick={()=>mach()}><span style={{color:'#00ffcc',cursor:'pointer',fontSize:'1.2rem'}}>{currentImageIndex<images.length-1?<FaArrowRight />:null}</span></button>
  }
  const CustomRightArrowButton =()=> {
    
      return <button style={{border:"blue",background:'none',position:'fixed',top:'50%',left:'1%',zIndex:2000}} onClick={()=>lmach()}><span style={{color:'#00ffcc',cursor:'pointer',fontSize:'1.2rem'}}>{currentImageIndex>0?<FaArrowLeft />:null}</span></button>
  }
 
  const CustomCloseButton =()=> {
      return <button aria-label="close button" style={{border:"blue",background:'none',position:'fixed',top:'60%',right:'1%',zIndex:20000}} onClick={()=>handleClose()}><span style={{color:'#00ffcc',fontSize:'1.2rem',cursor:'pointer'}}><FaWindowClose /></span></button>
  }
  return (
      <Layout site={data.site} >
        <SEO title="Bacchanale 2019 in Bacharach"
        description="Bacchanale zum Abschluss des Theaterfestivals 'An den Ufern der Poesie' im Oberen Mittelrheintal" />
      <div
        css={css`
          display:flex;
          flex-direction:column;
          width:100%;
          margin:0 auto 20px auto;
          padding:0;
          max-width:1200px;
          
        `}>
       <button
         onKeyPress={()=>{
          setCurrentIndex(0)
          handleClose()
          return 
        }} onClick={()=>{
          setCurrentIndex(0)
          handleClose()
          return 
        }} 
          css={css`
          background-color:transparent;
         width:100%;
         font-size:${theme.fontsizes.xgreat};
         font-weight: 500;
         text-align:center;
         
         
         @media(max-width:800px){
           font-size:${theme.fontsizes.middle};
         }
         img {
           object-fit:cover;
           width:100%; 
           object-position:50% 50%;
           max-height:450px;
         }
         
       `
        }><h1 
       >Bilder der Bacchanale</h1>
      
        <Img 
         css={css`
        object-fit:cover;
        width:100%;
          max-height:450px;
       
     `} fluid={data.theater.nodes[Math.floor(Math.random()*90)].childImageSharp.fluid} alt="Bildergalerie" />
</button>
</div>
{
  images ?
  <div css={css`
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
  `}>
   
  { images.map((item,index)=> {
    return <div key={index} css={css`
      display:flex;
      width:100px;
      height:100px;
      padding:0;
      margin:0;
      img {
        object-fit:cover;
        padding:0;
        margin:1px;
        width:100px;
        height:100px;
        cursor:pointer;
      }
     @media(max-width:580px){
      width:50px;
      height:50px;
      img {
        object-fit:cover;
        padding:0;
        margin:1px;
        width:50px;
        height:50px;
        cursor:pointer;
      }
     }
    `}
    > 
     <button 
       onKeyPress={()=>handleClose2(index,item)}
       onClick={()=>handleClose2(index,item)}
    >
      <img key={index} 
    src={item.src} alt="Bilder von der Bacchanale" />
    </button>
    </div>
  })}
</div>
:
<div className="mybounce">
<span>...lade Fotos</span>
</div>
}
     
       { light?
           <Lightbox
           isOpen={true}
           onPrev={gotoPrevious}
           onNext={gotoNext}
           images={images}
           currentIndex={currentImageIndex}
           style={{background:'black'}}
           /* Add your own UI */
           //renderHeader={() => <CustomHeader />}
           renderFooter={() => <CustomFooter />}
           renderPrevButton={() => <CustomLeftArrowButton />}
           renderNextButton={() => <CustomRightArrowButton />}
           renderCloseButton={() => <CustomCloseButton />}
           // renderImageOverlay={() => (<ImageOverlayComponent >)}
          
           /* Add styling */
           // className="cool-class"
           // style={{ background: "grey" }}
 
           /* Handle closing */
           
 
           /* Use single or double click to zoom */
           // singleClickToZoom
 
           /* react-spring config for open/close animation */
           // pageTransitionConfig={{
           //   from: { transform: "scale(0.75)", opacity: 0 },
           //   enter: { transform: "scale(1)", opacity: 1 },
           //   leave: { transform: "scale(0.75)", opacity: 0 },
           //   config: { mass: 1, tension: 320, friction: 32 }
           // }}
       />
       

           :null

       }
       {
          light && <div>
            <CustomCloseButton />
       
        <CustomFooter/>
        
        </div>
          }
         
    
      </Layout>
  );
};

export default Bacchanale;  

export const aktuellQuery = graphql`
    query {
        site {
          ...site
          siteMetadata {
            title
          }
        }
        theater: allFile (sort:{fields:relativePath},filter: {sourceInstanceName: {eq: "theater"}}){
          nodes {
           relativePath
           childImageSharp {
            fluid (maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
           } 
          
           
         }
         }
    }
    `


   